<template>
  <div id="jeopardy">
    <TitleHelper title="Let's Host Jeopardy!"></TitleHelper>
    
    <div v-if="countDown > 0" class="countdown-holder" @click="zeroTimer()">
        <div class="countdown">{{countDown}}</div>
    </div>

    <div class="link-helper" v-if="this.displayLinker">
        <div class="link-helper-dismiss" @click="closeDisplayLinker">x</div>
        <div>Here's the link to give to players:</div>
        <div style="color: #977700;">{{values.BACKEND_CONNECTION}}://{{values.BACKEND_SERVER}}/jeopardy-play/{{this.gameCode}}</div>
    </div>
    

    <div>
    <div style="font-family: 'jeopardy_font'; color: orange; text-shadow: 1px 1px black; font-size: 30px; margin: 10px;">
        <div>Show: <span style="color: #977700;">{{show_number}}</span>, Broadcast Number: <span style="color: #977700;">{{broadcast_number}}</span> <span style="font-size: 20px; color: #977700;">({{broadcast_date}})</span>&nbsp;<button @click="$cookies.remove('jeopardyBoard'); resetPlay(); getGame();" style="bottom: 5px; position: relative;">New Game</button></div>
        <div>Currently Playing: 
            <span style="color: #977700;">
                <span v-if="!jeopardyGameDone">Jeopardy</span>
                <span v-if="jeopardyGameDone && !doubleJeopardyGameDone">Double Jeopardy</span>
                <span v-if="jeopardyGameDone && doubleJeopardyGameDone">Final Jeopardy</span>
            </span>
        </div>
        <div style="display: flex; white-space: pre-wrap;">Game Code:&nbsp;<div style="color: #977700;">{{gameCode}}</div><div class="socketConnected" v-if="isConnected"></div></div>
        <div v-if="buzzOrder.length > 0">
            <span>Buzzes received from: 
                <span v-for="(i,n) in buzzOrder" :key=n>{{i}}<span v-if="n != buzzOrder.length - 1">, </span>
                </span>&nbsp;
                <button @click="resetBuzzers" style="position: relative; bottom: 5px;">Reset buzzers</button>
            </span>
        </div>
        <div v-else style="height: 36px;"></div>
    </div>

    <table v-if="!jeopardyGameDone" style="margin-top: 8px;">
        <tr>
            <td v-for="(item, index) in jeopardyBoard.categories" :key="'jc'+index" class="category-field">
                <span class="categories">{{item}}</span>
            </td>
        </tr>
        <tr v-for="(row,index) in jeopardyBoard.questions" :key="'jr'+index">
            <td v-for="(item,position) in row" :key="item.label" :class="{'selected':item.label == selected, 'disabled':item.answered == true}" @click="selectQuestion(item.label,index,position,item.value,'jeopardyBoard',$event)">
                <span v-if="item.answered==false" :class="{'selected':item.label == selected, 'disabled':item.answered == true}" class="money">
                    ${{item.value}}
                </span>
            </td>
        </tr>
    </table>
    <table v-if="jeopardyGameDone && !doubleJeopardyGameDone" style="margin-top: 8px;">
        <tr>
            <td v-for="(item, index) in doubleJeopardyBoard.categories" :key="'djc'+index" class="category-field">
                <span class="categories">{{item}}</span>
            </td>
        </tr>
        <tr v-for="(row,index) in doubleJeopardyBoard.questions" :key="'djr'+index">
            <td v-for="(item,position) in row" :key="item.label" :class="{'selected':item.label == selected, 'disabled':item.answered == true}" @click="selectQuestion(item.label,index,position,item.value,'doubleJeopardyBoard',$event)">
                <span v-if="item.answered==false" :class="{'selected':item.label == selected, 'disabled':item.answered == true}" class="money">${{item.value}}</span>
            </td>
        </tr>
    </table>

    <div class="questionBox">
        <span v-if="selected && (!jeopardyGameDone || !doubleJeopardyGameDone)">
            <div class="question">{{questions[selected]['question']}}</div>
            <div class="answer">{{questions[selected]['answer']}}</div>
            <button v-if="selectedValue" @click="skipQuestion()" style="position:absolute; bottom: 5px; right: 5px;">Skip question</button>
        </span>
        <div v-if="jeopardyGameDone && doubleJeopardyGameDone && categories && questions" style="margin-top: 8px;" class="finalJeopardy">
            <div>FINAL JEOPARDY CATEGORY: {{categories['FJ']}}</div>
            <div class="question">{{questions['FJ'].question}}</div>
            <div class="answer" style="color: yellow;">{{questions['FJ'].answer}}</div>
            <div style="display: flex;">
                <div style="font-family: 'Avenir'; width: 50%;">
                    <div style="font-weight: bold;">WAGERS SUBMITTED</div>
                    <div v-for="(f,n) in finalJeopardyWagers" :key="'fb'+n">
                        <span @click="selectAmount(f.amount)">{{f.player}} : <span :class="{'amountSelect': selectedValue == f.amount}">${{f.amount}}</span></span>
                        <button v-if="!f.accepted" 
                            style="background-color: green; position: relative; bottom: 5px; left: 5px;"
                            @click="acceptFinalJeopardyWager(f.player, f.amount)">accept</button>
                        <button v-if="!f.accepted" 
                            style="background-color: #b00; position: relative; bottom: 5px; left: 10px;"
                            @click="rejectFinalJeopardyWager(f.player)">reject</button>
                    </div>
                    <div v-if="!allWagersReceived">
                        <button style="background-color: white; position: relative; bottom: 5px;"
                            @click="selectAllWagersReceived">All wagers received & accepted</button>
                        <div style="font-size: x-small;">Click names above to select amounts</div>
                    </div>
                </div>
                <div style="font-family: 'Avenir'; width: 50%;">
                    <div style="font-weight: bold;">QUESTIONS SUBMITTED</div>
                    <div v-for="(f,n) in finalJeopardyQuestions" :key="'fb'+n">
                        {{f.player}} : {{f.question}} 
                    </div>
                </div>
            </div>
        </div>
    </div>  

    <div>
        <span v-for="(player,position) in players" :key="'player'+position" class="playerBox">
            <div v-if="player.control" class="control-indicator"></div>
            <div class="remove-div"><button @click="deletePlayer(position)" class="remove">x</button></div>
            <div class="player">
                <div class="player-name">{{player.name}}</div>
                <div class="score">
                    ${{player.score}}<br/>
                    <span class="correctors">
                        <div class="corrector" @click="scoreIncCorrect(position)">+</div>&nbsp;
                        <div class="corrector" @click="scoreDecCorrect(position)">-</div>
                    </span>
                </div>
                <div><button v-if="selectedValue" @click="rightAnswer(position)" class="right">Right</button></div>
                <div><button v-if="selectedValue" @click="wrongAnswer(position)" class="wrong">Wrong</button></div>
            </div>
        </span>
        <span class="playerBox" style="vertical-align: top; margin-top: 18px;">
            <div><input v-model="newPlayer" style="width: 95px;"></div>
            <div style="margin-top: 5px;"><button @click="addPlayer(newPlayer)">Add player</button></div>
        </span>
    </div>

    
    </div>
  </div>
</template>


<script>
import TitleHelper from '../../helpers/vueTitleHelper';
const axios = require("axios");
const config = require("../../assets/config");

export default {
  name: "jeopardyHost",
  beforeCreate: function() {
    document.body.className = 'jeopardy';
  },
  data() {
    return {
        isConnected: false,
        show: null,
        errorValue: null,
        values: config,
        categories: null,
        questions: null,
        show_number: null,
        broadcast_number: null,
        broadcast_date: null,
        jeopardyBoard: {},
        doubleJeopardyBoard: {},
        jeopardyBoardValueLookup: {
            1: 200,
            2: 400,
            3: 600,
            4: 800,
            5: 1000,
        },
        doubleJeopardyBoardValueLookup: {
            1: 400,
            2: 800,
            3: 1200,
            4: 1600,
            5: 2000,
        },
        selected: null,
        selectedBoard: null,
        selectedPosition: null,
        selectedValue: null,
        newPlayer: null,
        players: [],
        gameCode: null,
        buzzOrder: [],
        displayLinker: false,
        countDown: 0,
        finalJeopardyWagers: [],
        finalJeopardyQuestions: [],
        allWagersReceived: false,
        monthNames: {
         1: 'January',
         2: 'February',
         3: 'March',
         4: 'April',
         5: 'May',
         6: 'June',
         7: 'July',
         8: 'August',
         9: 'September',
         10: 'October',
         11: 'November',
         12: 'December'
        },
    };
  },
  mounted() {
      if (!this.$route.params.pathMatch ||
            (this.$cookies.isKey('gameCode') &&
            this.$route.params.pathMatch != this.$cookies.get('gameCode'))) {
        this.$cookies.remove('jeopardyBoard');
        this.getCode();
        this.getGame();
      } else {
        this.gameCode = this.$route.params.pathMatch;
        if (this.$cookies.isKey('existingGame')) {
            this.getGame(this.$cookies.get('existingGame'));
        } else {
            this.getGame();
        }
      }
      this.$socket.emit('joinJeopardyRoom', this.gameCode);
  },
  components: {
    TitleHelper,
  },
  sockets: {
    connect() {
        this.isConnected = true;
    },
    disconnect() {
        this.isConnected = false;
    },
    socketId(id) {
        if(this.$cookies.isKey('socketId')) {
            if (this.$cookies.get('socketId') != id) {
                this.$socket.emit('leaveJeopardyRoom', this.gameCode, this.$cookies.get('socketId'));
                this.$socket.emit('joinJeopardyRoom', this.gameCode);
                this.$cookies.set('socketId', id);
            }
        } else {
            this.$cookies.set('socketId', id);
            this.$socket.emit('joinJeopardyRoom', this.gameCode);
        }
    },
    newPlayerArrive() {
        this.$socket.emit('boardSend', this.gameCode, this.jeopardyBoard, this.doubleJeopardyBoard, this.show_number, this.broadcast_number, this.broadcast_date);
        this.$socket.emit('selectedSend', this.gameCode, this.selected, this.selectedBoard);
        this.$socket.emit('playersSend', this.gameCode, this.players);
        if (this.selected) {
            this.$socket.emit('questionSend', this.gameCode, this.questions[this.selected]['question']);
        }
    },
    buzzReceived(name) {
        this.buzzOrder.push(name);
        this.$cookies.set('buzzOrder', JSON.stringify(this.buzzOrder));
    },
    newPlayerAdd(name) {
        this.addPlayer(name);
        this.$socket.emit('boardSend', this.gameCode, this.jeopardyBoard, this.doubleJeopardyBoard, this.show_number, this.broadcast_number, this.broadcast_date);
        this.$socket.emit('selectedSend', this.gameCode, this.selected, this.selectedBoard);
        this.$socket.emit('playersSend', this.gameCode, this.players);
        if (this.selected) {
            this.$socket.emit('questionSend', this.gameCode, this.questions[this.selected]['question']);
        }
    },
    questionRequested() {
        this.$socket.emit('questionSend', this.gameCode, null);
    },
    changePlayerName(names) {
        var oldRec = this.players.filter((p) => { return p.name == names[0] })[0];
        this.players = this.players.filter((p) => { return p.name != names[0] });
        this.players.push({'name': names[1], 'score': oldRec.score });
        this.$socket.emit('playersSend', this.gameCode, this.players);
    },
    finalJeopardyWagerSubmitted(data) {
        let purgeRequired = false;
        for (var item of this.finalJeopardyWagers) {
            if (item.player == data[0]) {
                purgeRequired = true;
            }
        } 
        if (purgeRequired) {
            this.finalJeopardyWagers = this.finalJeopardyWagers.filter((i) => { return i.player != data[0] });
        }
        this.finalJeopardyWagers.push({'player':data[0],'amount':data[1],'accepted':false});
        this.$cookies.set('finalJeopardyWagers', JSON.stringify(this.finalJeopardyWagers));
    },
    finalJeopardyGuessSubmitted(data) {
        this.finalJeopardyQuestions.push({'player': data[0], 'question': data[1]});
        this.$cookies.set('finalJeopardyQuestions', JSON.stringify(this.finalJeopardyQuestions));
    }
  },
  methods: {
    getCode: function() {
      this.gameCode = Math.random().toString(36).replace('0.', '');
      this.$router.push({ path: '/jeopardy-host/' + this.gameCode});
      this.$cookies.set('gameCode', this.gameCode);
      this.displayLinker = true;
    },
    getGame: function(n) {
      this.$cookies.set('gameCode', this.gameCode);
      this.selected = null;
      if (!n) {
        this.finalJeopardyWagers = [];
        this.finalJeopardyQuestions = [];
        this.allWagersReceived = false;
        this.$cookies.remove('finalJeopardyWagers');
        this.$cookies.remove('finalJeopardyQuestions');
      } else {
        if (this.$cookies.isKey('finalJeopardyWagers')) {
            this.finalJeopardyWagers = JSON.parse(this.$cookies.get('finalJeopardyWagers'));
        }
        if (this.$cookies.isKey('finalJeopardyQuestions')) {
            this.finalJeopardyQuestions = JSON.parse(this.$cookies.get('finalJeopardyQuestions'));
        }
        if (this.$cookies.isKey('allWagersReceived')) {
            this.allWagersReceived = JSON.parse(this.$cookies.get('allWagersReceived'));
        }
      }
      this.selectedPosition = null;
      this.selectedValue = null;
      var config = {
        headers: {
          "Content-Type": "text/html; charset=UTF-8"
        }
      };
      var body = {  };
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/jeopardy/";
      if (n) {
        url += n.toString();
      } else {
        url += 'random';
      }
      axios
        .get(url,body,config)
        .then(response => {
            this.show = response['data'][0];
            this.show_number = this.show.show_number;
            this.broadcast_number = this.show.broadcast_number;
            let bd = new Date(this.show.broadcast_date);
            this.broadcast_date = bd.getDay().toString() + " " + this.monthNames[bd.getMonth()+1] + " " + bd.getFullYear().toString();
            this.categories = this.show.categories;
            this.questions = this.show.questions;
            this.$cookies.set('existingGame', this.show_number);
            if (this.$cookies.isKey('jeopardyBoard')) {
                this.jeopardyBoard = this.$cookies.get('jeopardyBoard');
                this.doubleJeopardyBoard = this.$cookies.get('doubleJeopardyBoard');
                this.$socket.emit('boardSend', this.gameCode, this.jeopardyBoard, this.doubleJeopardyBoard, this.show_number, this.broadcast_number, this.broadcast_date);
                if (this.$cookies.isKey('selected') && this.$cookies.get('selected') != 'null') {
                    this.selected = this.$cookies.get('selected');
                    this.selectedBoard = this.$cookies.get('selectedBoard');
                    this.selectedPosition = JSON.parse(this.$cookies.get('selectedPosition'));
                    this.selectedValue = parseInt(this.$cookies.get('selectedValue'));
                    this.$socket.emit('selectedSend', this.gameCode, this.selected, this.selectedBoard);
                }
                if (this.selected) {
                    this.$socket.emit('questionSend', this.gameCode, this.questions[this.selected]['question']);
                }
                if (this.$cookies.isKey('players')) {
                    this.players = JSON.parse(this.$cookies.get('players'));
                    this.$socket.emit('playersSend', this.gameCode, this.players);
                }
                if (this.$cookies.isKey('buzzOrder')) {
                    this.buzzOrder = JSON.parse(this.$cookies.get('buzzOrder'));
                }
            } else {
                this.constructBoards();
            }
        })
        .catch(error => {
            this.errorValue = error;
        })
    },
    constructBoards: function() {
        this.jeopardyBoard = {'categories': [], 'questions': [[],[],[],[],[]]}
        this.doubleJeopardyBoard = {'categories': [], 'questions': [[],[],[],[],[]]}
        for (var [key,value] of Object.entries(this.categories)) {
            if (key.startsWith('J')) {
                this.jeopardyBoard.categories.push(value)
            } else if (key.startsWith('DJ')) {
                this.doubleJeopardyBoard.categories.push(value)
            }
        }
        var label = ''
        var k = {}
        var round = 'J'
        var cat = 0
        var quest = 0
        for (quest = 1; quest <= 5; quest++) {
            for (cat = 1; cat <= 6; cat++)  {
                label = (round+'_'+cat.toString()+'_'+quest.toString());
                if (label in this.questions && this.questions[label].question != '=') {
                    k = {}
                    k['label'] = label;
                    k['value'] = this.jeopardyBoardValueLookup[quest];
                    k['answered'] = false;
                    this.jeopardyBoard.questions[quest-1].push(k);
                } else {
                    this.jeopardyBoard.questions[quest-1].push({'label':label,'answered':true,'value':null});
                }
            }
        }
        round = 'DJ'
        for (quest = 1; quest <= 5; quest++) {
            for (cat = 1; cat <= 6; cat++)  {
                label = (round+'_'+cat.toString()+'_'+quest.toString());
                if (label in this.questions && this.questions[label].question != '=') {
                    k = {}
                    k['label'] = label
                    k['value'] = this.doubleJeopardyBoardValueLookup[quest];
                    k['answered'] = false;
                    this.doubleJeopardyBoard.questions[quest-1].push(k);
                } else {
                    this.doubleJeopardyBoard.questions[quest-1].push({'label':label,'answered':true,'value':null});
                }
            }
        }
        this.$cookies.set('jeopardyBoard', this.jeopardyBoard);
        this.$cookies.set('doubleJeopardyBoard', this.doubleJeopardyBoard);
        this.$socket.emit('boardSend', this.gameCode, this.jeopardyBoard, this.doubleJeopardyBoard, this.show_number, this.broadcast_number, this.broadcast_date);
        this.$socket.emit('selectedSend', this.gameCode, this.selected, this.selectedBoard);
        this.$socket.emit('questionSend', this.gameCode, null);
    },
    selectQuestion(label,x,y,value,board,e) {
        if (e.target.classList.contains('disabled') || e.target.classList.contains('selected')){
            return;
        }
        if (this.selectedPosition) {
            this[board].questions[this.selectedPosition[0]][this.selectedPosition[1]]['answered'] = true;
        }
        this.selected = label;
        this.selectedBoard = board;
        this.selectedPosition = [x,y];
        this.selectedValue = value;
        this.$socket.emit('boardSend', this.gameCode, this.jeopardyBoard, this.doubleJeopardyBoard, this.show_number, this.broadcast_number, this.broadcast_date);
        this.$socket.emit('selectedSend', this.gameCode, this.selected, this.selectedBoard);
        this.$socket.emit('questionSend', this.gameCode, this.questions[this.selected]['question']);
        this.$socket.emit('resetBuzzers', this.gameCode);
        this.buzzOrder = [];
        this.storeGameStateToCookie();
        this.countDown = 0;
        setTimeout(() => {
            this.countDown = 9;
        }, 1000);
    },
    addPlayer(name) {
        let existing = this.players.filter((p) => { return p.name == name }).length;
        if (existing == 0) {
            this.players.push({'name': name, 'score': 0, 'control': false});
            this.$socket.emit('playersSend', this.gameCode, this.players);
        }
        this.$socket.emit('playersSend', this.gameCode, this.players);
        this.newPlayer = null;
        this.storeGameStateToCookie();
    },
    rightAnswer(pos) {
        this.resetControl();
        this.players[pos]['control'] = true;
        this.players[pos]['score'] = parseInt(this.players[pos]['score']) + this.selectedValue;
        if (!this.jeopardyGameDone || !this.doubleJeopardyGameDone) {
            this[this.selectedBoard].questions[this.selectedPosition[0]][this.selectedPosition[1]]['answered'] = true;
        }
        this.selected = null;
        this.selectedPosition = null;
        this.selectedValue = null;
        this.$socket.emit('boardSend', this.gameCode, this.jeopardyBoard, this.doubleJeopardyBoard, this.show_number, this.broadcast_number, this.broadcast_date);
        this.$socket.emit('selectedSend', this.gameCode, this.selected, this.selectedBoard);
        this.$socket.emit('playersSend', this.gameCode, this.players);
        this.$socket.emit('questionSend', this.gameCode, null);
        this.countDown = 0;
        this.storeGameStateToCookie();
        this.ifFinalJeopardySendCategory();
    },
    resetControl() {
        for (var pos = 0; pos < this.players.length; pos++) {
            this.players[pos]['control'] = false;
        }
    },
    scoreIncCorrect(pos) {
        this.players[pos]['score'] = parseInt(this.players[pos]['score']) + 100;
        this.$socket.emit('playersSend', this.gameCode, this.players);
        this.storeGameStateToCookie();
    },
    scoreDecCorrect(pos) {
        this.players[pos]['score'] = parseInt(this.players[pos]['score']) - 100;
        this.$socket.emit('playersSend', this.gameCode, this.players);
        this.storeGameStateToCookie();
    },
    wrongAnswer(pos) {
        this.players[pos]['score'] = parseInt(this.players[pos]['score']) - this.selectedValue;
        this.$socket.emit('playersSend', this.gameCode, this.players);
        this.storeGameStateToCookie();
    },
    deletePlayer(pos) {
        this.players.splice(pos,1);
        this.$socket.emit('playersSend', this.gameCode, this.players);
        this.storeGameStateToCookie();
    },
    skipQuestion() {
        this[this.selectedBoard].questions[this.selectedPosition[0]][this.selectedPosition[1]]['answered'] = true;
        this.selected = null;
        this.selectedPosition = null;
        this.selectedValue = null;
        this.$socket.emit('boardSend', this.gameCode, this.jeopardyBoard, this.doubleJeopardyBoard, this.show_number, this.broadcast_number, this.broadcast_date);
        this.$socket.emit('selectedSend', this.gameCode, this.selected, this.selectedBoard);
        this.$socket.emit('questionSend', this.gameCode, null);
        this.countDown = 0;
        this.storeGameStateToCookie();
        this.ifFinalJeopardySendCategory();
    },
    resetBuzzers() {
        this.$socket.emit('resetBuzzers', this.gameCode);
        this.buzzOrder = [];
        this.storeGameStateToCookie();
    },
    storeGameStateToCookie() {
        this.$cookies.set('jeopardyBoard', this.jeopardyBoard);
        this.$cookies.set('doubleJeopardyBoard', this.doubleJeopardyBoard);
        this.$cookies.set('selected', this.selected);
        this.$cookies.set('selectedBoard', this.selectedBoard);
        this.$cookies.set('selectedPosition', JSON.stringify(this.selectedPosition));
        this.$cookies.set('selectedValue', this.selectedValue);
        this.$cookies.set('players', JSON.stringify(this.players));
        this.$cookies.set('buzzOrder', JSON.stringify(this.buzzOrder));
    },
    closeDisplayLinker() {
        this.displayLinker = false;
    },
    playTimeUpSound () {
        // var audio = new Audio('../../../timeup.mp3');
        // audio.play();
        this.$socket.emit('timeUp', this.gameCode);
    },
    zeroTimer() {
        this.countDown = -1;
    },
    ifFinalJeopardySendCategory() {
        this.$socket.emit('finalJeopardyCategory', this.gameCode, this.categories['FJ']);
    },
    acceptFinalJeopardyWager(name, value) {
        this.$socket.emit('finalJeopardyWagerAccepted', this.gameCode, name, value);
        this.finalJeopardyWagers = this.finalJeopardyWagers.filter((i) => { return i.player != name});
        this.finalJeopardyWagers.push({ 'player' : name, 'amount' : value, 'accepted' : true});
        this.$cookies.set('finalJeopardyWagers', JSON.stringify(this.finalJeopardyWagers));
    },
    rejectFinalJeopardyWager(name) {
        this.$socket.emit('finalJeopardyWagerRejected', this.gameCode, name);
        this.finalJeopardyWagers = this.finalJeopardyWagers.filter((i) => { return i.player != name});
        this.$cookies.set('finalJeopardyWagers', JSON.stringify(this.finalJeopardyWagers));
    },
    selectAllWagersReceived() {
        this.allWagersReceived = true;
        this.$socket.emit('revealFinalJeopardy', this.gameCode, this.questions['FJ'].question);
        this.$cookies.set('finalJeopardyWagers', JSON.stringify(this.finalJeopardyWagers));
        this.$cookies.set('allWagersReceived', true);
    },
    selectAmount(amount) {
        this.selectedValue = amount;
    },
    resetPlay() {
        this.$socket.emit('resetPlayers', this.gameCode);
    }
  },
  watch: {
    countDown: function(val) {
        if (val > 0) {
            setTimeout(() => {
                this.countDown--;
            }, 1000);
        } else if (val == 0) {
            this.playTimeUpSound();
        }
    }
  },
  computed: {
    jeopardyGameDone: function() {
        if ('questions' in this.jeopardyBoard && this.jeopardyBoard.questions[0].length > 0) {
            for (var row of this.jeopardyBoard.questions){
                for (var item of row) {
                    if (item.answered == false) {
                        return false;
                    }
                }
            }
        }
        return true;
    },
    doubleJeopardyGameDone: function() {
        if ('questions' in this.doubleJeopardyBoard && this.doubleJeopardyBoard.questions[0].length > 0) {
            for (var row of this.doubleJeopardyBoard.questions){
                for (var item of row) {
                    if (item.answered == false) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
  }
};
</script>

<style scoped>

@font-face {
    font-family: 'jeopardy_font';
    src: url('./fonts/gyparody_hv-webfont.woff2') format('woff2'),
         url('./fonts/gyparody_hv-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dollar_font';
    src: url('./fonts/swiss911_xcm_bt-webfont.woff2') format('woff2'),
         url('./fonts/swiss911_xcm_bt-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'clue_font';
    src: url('./fonts/korinnaboldbt-webfont.woff2') format('woff2'),
         url('./fonts/korinnaboldbt-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

#jeopardy {
    background-image: linear-gradient(to bottom,purple 0%,darkblue 80%, purple 100%);
    min-height: calc(100vh)
}

table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}


td {
    height: 50px;
    text-align: center;
    vertical-align: middle;
    background-color: blue;
    border: 2px solid #3a251f;
    color: white;
    overflow-x: hidden;
    cursor: pointer;
}

td.selected {
    padding: 5px yellow;
    background-color: red;
    cursor: default;
}

td.disabled {
    cursor: default;
}

td span.money {
    font-size: xx-large;
    font-family: 'dollar_font';
    color: yellow;
    text-shadow: 2px 2px black;
    user-select: none;
}

td span.categories {
    font-family: 'clue_font';
    text-shadow: 1px 1px black;
}

td.category-field {
    cursor: default;
}

div.questionBox {
    margin: 10px 5px;
    min-height: 100px;
    background-color: blue;
    color: white;
    font-family: 'clue_font';
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    padding: 5px;
    position: relative;
}

div.questionBox div.question {
    color: white;
    padding: 5px;
    text-shadow: 1px 1px black;
}

div.questionBox div.answer {
    color: yellow;
    padding: 5px;
    text-shadow: 1px 1px black;
}

span.playerBox {
    margin: 10px 5px;
    display: inline-block;
    width: 100px;
    text-align: center;
    position: relative;
}

.playerBox button {
    width: 90%;
}

div.player {
    background-color: #507940;
    border-radius: 5px;
    font-family: "clue_font";
    color: white;
    text-align: center;
    position: relative;
    padding-bottom: 1px;
    overflow: hidden;
    padding: 3px 0 3px 0;
}

div.player .score {
    background-color: #2a3f22;
    font-family: "dollar_font";
    color: yellow;
    font-size: x-large;
    padding: 5px;
    width: 80%;
    margin: 5px auto;
    border-radius: 10px;
    user-select: none;
}

button {
    cursor: pointer;
}

button:hover, div.corrector:hover {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    background-color: #ccc;
}

div.player div.player-name {
    width: 88px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 6px;
    margin-right: 6px;
}

div.player button {
    margin: 3px;
    border: 0;
    border-radius: 3px;
}

div.player button.right {
    background-color: rgb(0, 180, 0);
    color: white;
}

div.player button.right:hover {
    background-color: rgb(0, 90, 0);
}

div.player button.wrong {
    background-color: rgb(160, 0, 0);
    color: white;
}

div.player button.wrong:hover {
    background-color: rgb(80, 0, 0);
}

div.remove-div {
    position: absolute;
    top: -10px;
    right: 14px;
    z-index: 1;
}

div.remove-div button.remove {
    position: absolute;
    background-color: grey;
    color: black;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    padding-bottom: 4px;
    padding-left: 6px;
    border: 1px black solid;
}

div.remove-div button.remove:hover {
    background-color: #303030;
}

div.socketConnected {
    background-color: green;
    border-radius: 100px;
    width: 15px;
    height: 15px;
    margin: 10px;
}

span.correctors div {
    display: inline-block;
    font-size: small;
    width: 12px;
    background-color: black;
    border-radius: 5px;
    cursor: pointer;
}

div.link-helper {
    position: fixed;
    background-color: black;
    color: orange;
    text-align: center;
    top: 100px;
    margin: 0 5%;
    font-family: 'Avenir';
    padding: 30px;
    border: 2px solid grey;
    width: 80%;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

div.link-helper-dismiss {
    position: absolute;
    right: 10px;
    top: 3px;
    font-size: large;
}

div.countdown-holder {
    position: absolute;
    user-select: none;
    right: 10px;
    border-radius:50px;
    top: 10px;
    padding: 5px;
    background: orange;
    border: 1px solid black;
    width: 50px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    z-index: 1;
}

div.countdown {
    font-family: 'dollar_font';
    font-size: 32px;
}

button {
    border: none;
    background-color: white;
    border-radius: 4px;
    font-family: 'Avenir';
    user-select: none;
}

div.finalJeopardy {
    font-family: 'clue_font';
    text-shadow: 1px 1px black;
    color: white;
    padding: 5px;
    text-transform: uppercase;
}

div.finalJeopardy div {
    margin: 5px;
}

div.control-indicator {
    position: absolute;
    background-color: greenyellow;
    width: 10px;
    height: 10px;
    z-index: 1;
    border-radius: 10px;
    top: 80px;
    left: 2px;
    border: 3px double black;
}

span.amountSelect {
    text-decoration: underline;
}

</style>
